/* make the customizations */
$theme-colors: (
        "primary": #2F3394,
        "secondary": #EB1C23,
        "malargueBg": #FAFAFA,
);

/* import bootstrap to set changes */

$btn-padding-y: 1.3em;
$btn-padding-x: 1.3em;

.btn-secondary{
        color: white !important
}

:root {
        --bs-primary: #2F3394 !important;
        --negro: #222222;
        --bg-primay: #FAFAFA; 
        --bs-secondary: #EB1C23 !important;
        --bs-error: red !important;

        overflow-x: hidden;

        body {
                font-family: poppins !important;
                background-color: white;
                box-sizing: border-box;
               
        }
}

@media (max-width: 400px){
        .row{
                --bs-gutter-x: 0px !important;
               
        }
}


@import "../node_modules/bootstrap/scss/bootstrap";

