nav {
    background-color: transparent !important;
    transition: 0.3s all;


   

}

#NavBarHome {
    a {
        color: white !important;
        text-decoration: none;
        margin-left: 1em;
    }
    .dropdown-menu-dark{
        border: 0px !important;
        padding-left: 15px;
        a{
            color: black !important;
            &:active{
                background-color: var(--bs-primary);
                color: black !important;
                // padding: 0px !important;
                margin: 0px !important;
                background-size: cover;
                // margin-left: -8px !important;
                // padding-left: 8px;
                border: 0px !important;
                padding-left: 15px !important;
            }
        }
    }


    @media (max-width: 990px) {

        .menuBar01{
            display: block;
            align-content: flex-start !important;
            justify-content: flex-start !important;
            background-color: white !important;
            padding: 1em 0 1em 0;
        }

        .dropdown-menu{
            z-index: 20;
            a{
                margin-left: 15px !important;
                color: black !important;
                padding-top: 0em;
                padding-bottom: 0em;
     
            }
            background-color: transparent !important;
        }

        a {
            color: black !important;
        }
        .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
            color: black !important;
        }

    }

    .navbar-toggler{
        margin-right: 20px;
    }
    
}

.dropdown-menu{
    a{
        margin-left: 0px !important;
        color: var(--negro) !important;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        &:active{
            background-color: transparent !important;
            color: black !important;
            padding-top: 0.5em !important;
            padding-bottom: 0.5em !important;
            margin-left: 0px !important;
        }
        
    }
    background-color: white !important;
}

.activeNav{
    background-color: var(--bs-primary) !important ;

}

.navbar-toggler-icon{
    background-image: url(../../assets/img/SVG/dropdownIcon.svg) !important;
    // margin-right: 43px !important;
}
