#Nosostros{
    padding-bottom: 92px;
    // padding-left: 1em;
    // padding-right: 1em;

    #main{
        padding-left: 1.2em;
        padding-right: 1.2em;
    }

    h2{
        text-align: left;
        font: normal normal 600 34px/36px Poppins;
        letter-spacing: 0px;
        color: #2F3394;
        opacity: 1;
        padding-bottom: 31px;
        
    }
    p{
        text-align: left;
        font: normal normal normal 14px/22px Poppins;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
    }

    h3{
        text-align: left;
        font: normal normal 600 24px/35px Poppins;
        letter-spacing: 0px;
        color: #2F3394;
        opacity: 1;
        margin-bottom: 0px;
        
    }
    .img-fluid{
        padding-top: 92px;
        width: 100%;
        padding-right: 36px;
        
    }
    .content{
        // margin-left: 60px;
    }
    .subTitle{
        text-align: left;
        font: normal normal normal 18px/24px Poppins;
        letter-spacing: 0px;
        color: #EB1C23;
        opacity: 1;
        padding-bottom: 0px;
        margin-bottom: 0px;
        padding-top: 92px;

    }

    @media (max-width: 420px){
        .img-fluid{
            padding-right: 0px;
        }
    }
}