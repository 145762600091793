#Header{
    
    // &::after{
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     background-color: black;
    //     height: 100%;
    //     width: 100%;
    //     opacity: 0.2;
    // }
    
    .hero-landing-1{
        height: 771px;
    }
    .hero-title-big{
        position: relative;
        margin-top: 150px;
        padding-right: 55px;
        z-index: 2;
        
    
    }
    a{
        text-decoration: none;
        color: white;
    }


    
} 

@media (max-width: 720px){
    #Header{
        .hero-title-big{
            padding-right: 0px !important;
        }

        .btn-primary, .btn-secondary {
            margin-bottom: 1em;
        }

        .hero-landing-1{
            height: 130vh;
            background-size: cover;
            background-position: right -440px top -10px;
            // background-position: right;
        }
        
        
    }
    
}


// Mobile 
@media (max-width: 420px) {
    #Header{
        h1 {
            align-items: center;
            display: flex;
            font-weight: 700;
            font-size: 27px;
            line-height: 44px;
            padding-top: 70px;
        }
        
        .hero-landing-1 {
            background-position: right -440px top -130px;
            padding: 18px !important;
            height: 128vh;
            min-height: 1050px;
            
            .container{
                padding: 0px !important;
            }
        }

        .btn{
            border-radius: 8px;
            margin-bottom: 25px !important;
            font-size: 17px ;
            font-weight: 600;
        }

        


    }
}