#Tracking{
    
    // a{
    //     // color: var(--bs-primary) !important;
    //     text-decoration: none;
    //     text-align: center;
    //     // font: normal normal 600 21px/31px Poppins;
    //     letter-spacing: 0px;
    //     opacity: 1;
    //     border: 1px;
        
    //     :hover{
    //         background-color: var(--bs-secondary);
    //         border-radius: 50%;
    //         #contactanos svg {
    //             fill: white
    //         }
    //     }
    // }
    
    .loader {
        border: 10px solid #f3f3f3;
        border-top: 10px solid #3498db;
        border-radius: 50%;
        width: 80px;
        height: 80px;
        animation: spin 1s linear infinite;
      }
    .heroTitle{

        text-align: center;
        font: normal normal 700 28px/57px Poppins;
        letter-spacing: 0px;
        color: #2F3394;
        opacity: 1;
        padding-top: 91px;
        margin-bottom: 30px;
    
 
    }

    h2{
        margin-top: 118px ;
        margin-bottom: 0px;
        text-align: left;
        // font: normal normal 600 42px/50px Poppins;
        letter-spacing: 0px;
        color: #2F3394;
        opacity: 1;
    }

    h3{
        margin-top: 100px;
        font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 16px/var(--unnamed-line-spacing-38) var(--unnamed-font-family-poppins);
        letter-spacing: var(--unnamed-character-spacing-0);
        color: var(--unnamed-color-2f3394);
        text-align: left;
        font: normal normal normal 16px/38px Poppins;
        letter-spacing: 0px;
        color: var(--bs-primary);
        opacity: 1;
    }

    .step{
        height:120px;
        position: relative;

    }

    p{
        text-align: left;
        font: normal normal normal 12px/24px Poppins;
        letter-spacing: 0.12px;
        color: #222222;
        opacity: 0.8;
        padding-left: 10px;
        padding-top: 0px;
    }   .title{
            font: var(--unnamed-font-style-normal) normal 600 var(--unnamed-font-size-24)/30px var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-2f3394);
            text-align: left;
            font: normal normal 600 24px/26px Poppins !important;
            letter-spacing: 0px;
            color: var(--bs-primary);
            // margin-bottom: 1em;
            
            
        .data{
            font: var(--unnamed-font-style-normal) normal var(--unnamed-font-weight-normal) 18px/30px var(--unnamed-font-family-poppins);
            letter-spacing: var(--unnamed-character-spacing-0);
            color: var(--unnamed-color-222222);
            text-align: left;
            font: normal normal normal 18px/15px Poppins !important;
            letter-spacing: 0px;
            color: #222222;
            
        }
    }

    input{
        margin: 0px !important;
        border-color: var(--bs-primary);
        border-radius: 0px;
        height: 36px;
        // margin-bottom: 50px !important;
    }

    .btn-primary{

        border-radius: 0px 5px 5px 0px;
        height: 36px;
        padding: 10px;
        margin: 0px;
        width: 100%;
        // border-color: var(--bs-secondary) !important; 
        border-width: 0px;
        // margin-right: 2em !important;
          
        
        svg{
            // fill: var(--bs-primary);
            margin-top: -4%;
            margin-left: -2%;
            :hover {
                fill: white !important;
                border-radius: 50%;
            }

        }

    }

    .bullet{
        background-color: lightgray;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        position: relative;
        padding: 0px;
        display: flex;
        z-index: 5;

        &::before{
            content: "";
            height: 278%;
            width: 2px;
            background-color: lightgray;
            display: flex;
            margin-left: 15px;
            align-self: center !important;
            position: absolute;
            margin-bottom: 119px;
            z-index: 4;
        }

        
        
    }
    
    // .step:last-child{
    //     background-color: #31B121 !important;
    // }
    .step:last-child .bullet{
        background-color: #31B121 !important;
    }
    .step:first-child .bullet{
        &::before{
            height: 0px;
        }
    }

    .completed {
        background-color: #31B121 !important;
        &::before{
            background-color: #31B121 !important;
        }
        &:last-child{
            background-color: red !important;
        }
    }
    .completed:last-of-type{
        background-color: blue !important;
    }
}
