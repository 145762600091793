#sucursales{

    background-color: var(--bg-primay);

    h1{
        color: var(--bs-primary);
        text-align: center;
        font: normal normal 600 40px/50px Poppins;
        letter-spacing: 0px;
        // color: #2F3394;
        opacity: 1;
        padding: 134px 0 2em 0;

    }

    @media (max-width:420px){

        h1{
               
            padding: 50px 0 1em 0 !important;

        }

    }



    #listaDeSucusrales{

        padding: 0 0 3em 0;

        h3{
            text-align: left;
            font: normal normal medium 24px/82px Poppins;
            letter-spacing: 0px;
            color: var(--bs-primary);
            opacity: 0.9;
            
        }
        @media (max-width:420px){
            svg{
                height: 25px;
                
            }



            h3{
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
                margin-left: 0.3rem;
            }
            p{
                font-weight: 500;
                font-size: 10px;
                line-height: 15px;
                margin-left: 0.3rem;
            }
        }
    }

}
