#cotiza {
    #main {
      #FormCotizador {
        .border_active {
          border-radius: 0px !important;
        }
        .form-container{
          margin-top: 45px;
          padding-bottom: 40px;
        }
        @media (max-width: 768px) {
          .form-container{
            box-shadow: none !important;
          }
        }
        h2 {
          font-style: normal;
          font-weight: 600;
          font-size: 29px;
          line-height: 45px;
          color: var(--bs-primary) !important;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        label {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 5px;
          color: var(--bs-primary);
        }
        .input_container {
          margin-top: 0px;
          margin-bottom: 2em;
        }
        .input_container__field {
          border: solid 1px var(--bs-primary) !important;
          background-color: white !important;
          border-radius: 0px !important;
          width: 100%; /* Add this line to ensure the input field takes up full width */
          transition: border-color 0.3s; /* Add transition for smooth border color change */
          &:focus {
            border-color: transparent !important; /* Change to transparent to hide the focus border */
          }
        }
        .input_container__field_error {
          border-color: var(--bs-error) !important;
        }
      }
    }
  
    @media (max-width: 420px) {
      #main {
        padding-left: 1.5em !important;
        padding-right: 1.5em !important;
      }
    }
  }
 