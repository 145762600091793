.contenidoEncomienda{
    padding: 96px 0 96px 0;
    h2{
       
        padding-bottom: 31px;
        color: var(--bs-primary);
        text-align: left;
        font: normal normal 600 40px/42px Poppins;
        letter-spacing: 1.44px;
        opacity: 1;
    }
    p{
        padding-right: 96px;
        text-align: left;
        letter-spacing: 0px;
        opacity: 0.8;
        font: normal normal normal 14px/24px Poppins;
    }
    a{
        text-align: left;
        font: normal normal 600 14px/24px Poppins;
        letter-spacing: 0px;
        color: #EB1C23;
        text-decoration: none;
    }
}

@media (max-width: 420px){

    .contenidoEncomienda{

        padding-left: 1em !important;
        padding-right: 1em !important;
        padding-top: 0px !important;
        padding-bottom: 1em !important;
        background-color: white;

        img{
            padding-bottom: 1em !important;
        }


        h2{ 
            padding-top: 2em !important;
            padding-bottom: 0.5em !important;
            font-weight: 600 !important;
            font-size: 32px !important;
            line-height: 48px !important;

        }

        p{
            padding: 0px !important;
            color: black !important;
            opacity: 1 !important;
        }
    }


}