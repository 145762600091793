#Novedades{
    // min-height: 787px;
    background-color: #FAFAFA;
    

    h2{
        padding: 87px 0 0 0;
        color: var(--bs-primary);
        margin-left: 0.5em;
        font-size: 42px;
        font-weight: 600;
    }


    img{
        margin-top: 66px
    };

    .btn{
        border: 0px;
        padding: 0 0 0 0;
        margin-left: 1em;
     
    }
    .btn-outline-primary{
        font-size: 17px !important;
        font-weight: 600;
    }

    .btn-outline-primary:hover{
        background-color: transparent !important;
        color: var(--negro)
        
    }

    .card-body{

        img{
            height: 241px !important ;
            object-fit: cover !important;

        }
        
    }

    .card{
        background-color: transparent;
        border: 0px;

        
        .card-title{
            margin-top: 2em;
            font-size: 24px;
            font-weight: 600;
            color: #222222;
            
        }
        .text-muted{
            margin-bottom: 4em !important;
            font-size: 17px;
            font-weight: 600;
            color: var(--negro) !important;
            opacity: 0.7;
        }
        p{
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            color: var(--negro) !important;
            opacity: 0.7;
        }
    }
}

@media (max-width: 767px){

    #Novedades{

        margin-left: 0px;
        margin-right: 0px;
        
        // padding: 20px;
        .card-body{
            padding: 0px;
        }
    }

}

@media (max-width: 420px){

    #Novedades{

        padding-right: 2em;
        padding-left: 2em;

        h2{
            padding: 1em 0 1em 0;
            font-weight: 700;
            font-size: 32px;
            line-height: 48px;
        }
        img{
            margin-top: 0px;
        }

        h5 {
            padding: 0px !important;
            margin: 1em 0 0 0 !important;
            font-weight: 700 !important;
            font-size: 23px !important;
            line-height: 25px !important;
        }
        
        .text-muted{
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 34px !important;
            opacity: 1 !important;
            color: black !important;
        }

        .card-text{
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 20px !important;

        }

        .btn{
            margin-left: 0px !important;
            font-weight: 600 !important;
            font-size: 16px !important;
            line-height: 20px !important;
            margin-bottom: 2em;
        }

        .card-body{
            img{
                height: 200px ;
                object-fit: cover;

            }
            


        }
    }

}