#footerMalargue{
    background-color: #ededed;
    height: 372px;
    padding-top: 67px;
    h2{
        a{
            color: var(--bs-primary);
            text-decoration: none;
            text-align: left;
            font: normal normal 600 21px/31px Poppins;
            letter-spacing: 0px;
            opacity: 1;
        }

    }
    p{  
        font-size: 14px;
        text-align: left;
        letter-spacing: 0px;
        color: #222222;
        opacity: 0.8;

    }
    .btn-outline-primary{
        background-color: var(--bs-primary);
        height: 52px;
        width: 52px;
        border-radius: 50%;
        padding: 0px;
        border: 0px;
        :hover{
            background-color: transparent !important;
        }   
        svg{
            // fill: var(--bs-primary);
            :hover {
                fill: white !important;
                border-radius: 50%;
            }

        }
    }
    #contactMail{
        font-size: 14px;
        text-align: left;
        font: normal normal medium 14px/21px Poppins;
        letter-spacing: 0px;
        color: #222222;
        opacity: 0.8;
        a{
            text-decoration: none;
            color: #222222;
        }

    }
    .container{
        border-bottom: 1px solid rgba(112, 112, 112, .2);
        padding-bottom: 0em
        
    }
    .mediosDePago{
        margin-top: -10px;
    }
    .footerStrong{
        text-align: right !important;
        text-align: left;
        font: normal normal normal 14px/21px Poppins;
        letter-spacing: 0px;
        color: #222222;
        opacity: 1;
        padding-top: 5px;
    }
}

    #termsAndCond{
        padding-bottom: 3em;
        background-color: #ededed !important;
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        text-align: center !important;
    }

@media (max-width: 400px){

    #footerMalargue{
        height: unset;

        #contactMail{
            font-size: 10px;
            text-align: right !important;
            padding-left: 10px;
            // margin-right: 30px;
        }

        #socialContact{
            display: flex;
            justify-content: space-between!important;
            // background-color: aqua;
            a{
                // margin-left: 10px !important;
            }
        }

        .mediosDePago{
            margin-bottom: 90px;
            align-content: center;
        }
        #address{
      
            align-content: center;
            img{
           
                margin-top: 20px;
          
            }
        }
        .footerStrong{
            text-align: left !important;
            margin-top: -50px;
            padding-left: 15px;
        }
    }
}