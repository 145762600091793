

#BannerHeader{

    height: 434px !important;
    // background-image: url(../../assets/img/foto-header.jpg);
    background-position: center;
    background-size: cover;

    #title{
        z-index: 10;
        position: relative;

    }

    #overlay{
        position: absolute;
        top: 0%;
        background-color: black;
        opacity: 0.2;
        z-index: 1;
        height: 434px !important;
    }

    h1{
        text-align: left;
        font: normal normal medium 38px/42px Poppins;
        letter-spacing: 0px;
        color: #FAFAFA;
        opacity: 1;
        padding: 0px 0px 15px 0px;
        span{
            text-align: left;
            font: normal normal normal 20px/30px Poppins;
            letter-spacing: 0px;
            color: #FAFAFA;
            opacity: 0.8;
        }

    }



    
    .linea {
        border-top: 4px solid #EB1C23;
        height: 4px;
        max-width: 247px;
        padding-bottom: 55px;
        // margin: 0 auto 0 auto;
    }


}
