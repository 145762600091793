$over-lap: -120px;

#CadrDeck{
    z-index: 2;
    position: relative;

    padding: 55px 25px 15px 25px;    
    margin-top: $over-lap;
    background-color: white !important;


        

        .btn {
            display:block;
            height: 54px;
            width: 54px;
            border-radius: 50%;
            padding: 0px;
            margin: 0 0 25px 0;       
            img {
                // width: 25px;
                // height: 25px;
            }
        }
        .card {
            border-radius: 0px !important;
            border-width: 0px;
            padding-top: 0 !important;
            margin-top: 35px !important;
            background-color: transparent !important;


            p {
                margin-bottom: 0px !important;
            }

            h5, ul, p{
                padding-left: 50px;
            }

            ul{
                list-style: '- '
            }

            li{
                text-align: left;
                font: normal normal normal 14px/31px Poppins;
                letter-spacing: 0px;
                color: #222222;
                opacity: 0.8;
            }
        }
        .card-body{
            padding-top: 0px;
        }

        #subTitle{
            .row{

                :nth-child(3){
                    padding: 0 0 0 38px !important;

                }
                :nth-child(2){
                    padding: 0 0 0 32px !important;
                }
                :nth-child(1){
                    padding: 0 0 0 22px !important;
                }
            }
            p{
                margin: 0px;
 
            } 
        }
        #central {
            z-index: 3;
            position: relative;
            &::before{
                content: "";
                background-color: #222222;
                display: block;
                height: 100%;
                width: 1px;
                position: absolute;
                opacity: 0.3;
                left: -15px;
            }
            &::after{
                content: "";
                background-color: #222222;
                display: block;
                height: 100%;
                width: 1px;
                position: absolute;
                opacity: 0.3;
                right: -15px;
                top: 0px;
            }
            
 
            
            
        }
        .card-title{
            text-align: left;
            font-size: 32px;
            letter-spacing: 0px;
            color:  var(--bs-primary) !important;
            margin-top: 0px !important;
        }
        .card-text{
            font-size: 14px;
            color: #222222;
            opacity: 0.8;
        }
        .card-muted{
            font-size: 17px;
            color: #222222;
            opacity: 0.8;
            margin-bottom: 0px !important;
            
        }

        // .whatsapp {
        //     position:relative;
        //     width:60px;
        //     height:60px;
        //     bottom:40px;
        //     color:#FFF;
        //     z-index:100;
        //   }
          
        //   .whatsapp-icon {
        //     margin-top:13px;
        //   }

        .whatsapp {
          position:fixed;
          width:60px;
          height:60px;
          bottom:40px;
          right:40px;
        //   background-color:#25d366;
          color:#FFF;
          border-radius:50px;
          text-align:center;
          font-size:30px;
          z-index:100;
        }

        .whatsapp-icon {
          margin-top:13px;
        }

    @media (max-width: 760px) {
        #central {
            
            &::before{
                display: block;
                width: 100%;
                height: 1px;
                bottom: -150px;
                left: 0px;
            }
            &::after{
                display: block;
                width: 100%;
                height: 1px;
                top: -90px;
                left: 0px;
            }
        }
        .card {
            margin-bottom: 50px;
        }
        #deck{
            margin-top: -90px;
            background-color: white !important;
        }
    }

    
}

// Mobile
@media (max-width: 420px) {

    #CadrDeck{
        
        margin-top: -220px !important;

        #deck{
            margin-top: -110px;
            margin-bottom: -2rem !important;
            
        }
    

        .card-title, .card-text{
            padding: 0px !important;
        }

        .text-muted{
            color: black !important;
            font-weight: 400 !important;
            font-size: 18px !important;
            line-height: 46px !important;
            opacity: 1 !important;
            
        }

        .card-title{
            font-weight: 700 !important;
            font-size: 24px !important;
            line-height: 20px !important;
            padding-bottom: 1em !important;
        }

        .card-text{
            font-weight: 400 !important;
            font-size: 14px !important;
            line-height: 16px !important;
            
        }

        .card{
            ul{
                color: black !important;
                padding-left: 10px !important;
                li{
                    font-weight: 600 !important;
                    font-size: 14px !important;
                    line-height: 16px !important;
                    padding-bottom: 1.2em;

                }
            }
        }

        .ECommerce, .Empresas, .Individuos{

                img{
                height: 269px;
                overflow: hidden;
                object-fit: cover;
               
            }

        }

        .Individuos{
            img{
                object-position: top;
            }
        }
        .ECommerce{
            img{
                object-position: bottom;
            }
        }

        .btn{
            height: 40px;
            width: 40px;
            margin: 0 0 0 0; 
            img{
                height: 30px;
                width: 30px;
            } 
        }

        #central {
            
            &::before{
                top: 600px !important;
            }
            &::after{
                bottom: 500px !important;

            }
        }

    
    }
}