#BannerHome{
    height: 768px;
    background: var(--bs-primary);
    z-index: 3;
    h1{
        color: white;
        font-size: 42px;
        font-weight: 600;
        font-family: poppins;
        padding-left: 0.5em;
        padding-top: 4em;
        
    }
    .imgBanner{
        
        background-image: url(../../assets/img/camioneta2.png);
        background-repeat: no-repeat;
        background-position: bottom right;
        background-size: 80%;
    }
    
    
}

@media (max-width: 767px){

    #BannerHome{
        height: 690px;
        background-size: contain;
        background-position: bottom;
        h1{
            font-size: 26px;
            padding: 140px 0px;
            margin: 0px;
            text-align: center;
    
    
        }
        .imgBanner{
            background-position: bottom 200px center;
        }
    }
}

@media (max-width: 420px){
    
    #BannerHome{

        height: 500px;
        
        h1{

            font-weight: 700;
            font-size: 27px;
            line-height: 40px;
            padding: 110px 0px;

        }
        
        .imgBanner{
            background-size: 90%;
            background-position: bottom 50px center;
        }

    }

}