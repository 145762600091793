#blogTips{
    h2{
        margin-top: 2em;
        font-weight: 600;
        font-size: 30px;
        line-height: 45px;
        color: var(--bs-primary);
    }
    h4{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}