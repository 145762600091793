#searchBox{

    padding: 0px !important;

    margin: 0px !important;

    .container{
        padding: 0px !important;
    }

    input{
        margin-left: -10px;
        width: 55%;
        border-radius: 5px 0px 0px 5px;
    }

    .btn{
        width: 20%;
        line-height: 1;
    }

}